import './login.scss';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ModalStyle} from './Util';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { connect } from 'react-redux';

function isValidEmailAddress(address) {
    return !! address.match(/.+@.+/) && address.indexOf('.') > -1 && address.indexOf(' ') < 0;
}

const mapStateToProps = (state) => {
    return {
        user: state.userStore.user,
    }
}

class LoginComponent extends React.Component {

    constructor(props) {
        super();
        this.onChangeEmailInput = this.onChangeEmailInput.bind(this);
        this.handleEmailLoginButton = this.handleEmailLoginButton.bind(this);

        this.state = {
            email: '',
            error: false,
            emailIsValid: false,
            emailIsSent: false,
            loading: false
        }
    }

    onChangeEmailInput(event) {
        this.setState({email: event.target.value});
    }

    handleEmailLoginButton() {
        if (this.state.email === '' || this.state.email.indexOf('@') < 0 || this.state.email.indexOf('.') < 0 || this.state.email.indexOf(' ') > -1) {
            return;
        }

        this.setState({loading: true});

        fetch(`${process.env.REACT_APP_API_URL}/request-login-token`,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: this.state.email})
            })
            .then(response => {
                if (!response.ok) {
                    this.setState({error: true});
                }

                this.setState({loading: false, emailIsSent: true});
            })
            .catch(error => {
                this.setState({error: true, loading: false});
            });
    }

    render() {
        return (
            <div className={'login-container'}>
                <Box sx={ModalStyle}>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        <div>
                            {this.props.text}
                        </div>

                        <div className='oauth2-option'>
                            <a href={`${process.env.REACT_APP_API_URL}/api/login/google`} className='google'>
                                <img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNy42NCA5LjIwNWMwLS42MzktLjA1Ny0xLjI1Mi0uMTY0LTEuODQxSDl2My40ODFoNC44NDRhNC4xNCA0LjE0IDAgMCAxLTEuNzk2IDIuNzE2djIuMjU5aDIuOTA4YzEuNzAyLTEuNTY3IDIuNjg0LTMuODc1IDIuNjg0LTYuNjE1eiIgZmlsbD0iIzQyODVGNCIgZmlsbC1ydWxlPSJub256ZXJvIj48L3BhdGg+PHBhdGggZD0iTTkgMThjMi40MyAwIDQuNDY3LS44MDYgNS45NTYtMi4xOGwtMi45MDgtMi4yNTljLS44MDYuNTQtMS44MzcuODYtMy4wNDguODYtMi4zNDQgMC00LjMyOC0xLjU4NC01LjAzNi0zLjcxMUguOTU3djIuMzMyQTguOTk3IDguOTk3IDAgMCAwIDkgMTh6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD48cGF0aCBkPSJNMy45NjQgMTAuNzFBNS40MSA1LjQxIDAgMCAxIDMuNjgyIDljMC0uNTkzLjEwMi0xLjE3LjI4Mi0xLjcxVjQuOTU4SC45NTdBOC45OTYgOC45OTYgMCAwIDAgMCA5YzAgMS40NTIuMzQ4IDIuODI3Ljk1NyA0LjA0MmwzLjAwNy0yLjMzMnoiIGZpbGw9IiNGQkJDMDUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPjxwYXRoIGQ9Ik05IDMuNThjMS4zMjEgMCAyLjUwOC40NTQgMy40NCAxLjM0NWwyLjU4Mi0yLjU4QzEzLjQ2My44OTEgMTEuNDI2IDAgOSAwQTguOTk3IDguOTk3IDAgMCAwIC45NTcgNC45NThMMy45NjQgNy4yOUM0LjY3MiA1LjE2MyA2LjY1NiAzLjU4IDkgMy41OHoiIGZpbGw9IiNFQTQzMzUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPjxwYXRoIGQ9Ik0wIDBoMTh2MThIMHoiPjwvcGF0aD48L2c+PC9zdmc+' height='20' width='20' />
                                <span>Google</span>
                            </a>
                        </div>
                        <div className='oauth2-option'>
                            <a href={`${process.env.REACT_APP_API_URL}/api/login/facebook`} className='facebook'>
                                <svg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='ui-svg-inline'><path d='M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z'></path></svg> <span className='ml-2'>
                            Facebook
                        </span>
                            </a>
                        </div>
                        {!this.state.emailIsSent &&
                            <div className='oauth2-option'>
                                <div className='email'>
                                    <input type="email" placeholder={'Email'}
                                           value={this.state.email}
                                           onChange={event => {
                                               this.setState({
                                                   email: event.target.value,
                                                   emailIsValid: isValidEmailAddress(event.target.value)
                                               });
                                           }}
                                           onBlur={() => this.setState({
                                               emailIsValid: isValidEmailAddress(this.state.email)
                                           })}/>
                                    <ArrowCircleRightIcon
                                        className={this.state.emailIsValid ? 'valid' : 'invalid'}
                                        onClick={() => this.handleEmailLoginButton()}/>
                                </div>
                            </div>
                        }

                        {this.state.emailIsSent &&
                            <div className='oauth2-option'>
                                <p>Sizga email yuborildi, iltimos elektron pochtangizni tekshiring - {this.state.email}</p>
                            </div>
                        }

                        <div style={{color: 'darkgrey', marginTop: '20px', fontSize: '15px'}}>
                            Sahifamizdan foydalanishingiz, <a href={`${process.env.REACT_APP_API_URL}/privacy-policy`} target='_blank'>Foydalanish shartlari</a> va <a href={`${process.env.REACT_APP_API_URL}/privacy-policy`} target='_blank'>Maxfiylik siyosati</a> shartlarini qabul qilganingizni anglatadi
                        </div>
                    </Typography>
                </Box>
            </div>
        );
    }

};

export default connect(mapStateToProps)(LoginComponent);
